<template>
	<div>
		<not-bind :type="1" @complete="(e)=>{IsBind = e}" v-if="!IsBind&&!Total"></not-bind>
		<div style="background: #fff;padding: 15px;" class="ml-goodList" v-loading = "pageloading" v-else>

			<div class="filter-container">
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" placeholder="商品标题、商品编码" style="width:200px;margin-right: 10px;" clearable
					@keyup.enter.native="goodsFilter"></el-input>
				</div>
				<div class="filter-item">
					<label class="label">上下架状态: </label>
					<el-select v-model="Status" placeholder="请选择" style="width: 200px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in StatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<el-button type="primary" @click="goodsFilter">查询</el-button>
					<buttonPermissions :datas="'videoGoodsListaddProduct'">
						<el-button type="primary" @click="eidtGroup({})" style="margin-left:10px">发布商品</el-button>
					</buttonPermissions>
				</div>
			</div>
			<!-- 表格 -->
			<div class="table-container">
				<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable" empty-text="查询不到相关商品" >
					<!-- <el-table-column type="selection" width="55"></el-table-column> -->
					<el-table-column label="商品" min-width="200">
						<template slot-scope="scope">
							<div class="flex flex-align-center">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'" style="width:60px;height:60px">
								<svg-icon v-else icon-class="noImgIcon" />
								<div class="margin-left-5 flex-1" style="width:0">
									<div style="cursor: pointer;" class="ellipsis-two color-409eff" @click="toQiDianShop(scope.row)">
										{{scope.row.Title}}
									</div>
									<div class="ellipsis" style="color:#909399">{{scope.row.ProductNo}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Nickname" label="归属小店">
						<template slot-scope="scope">
							<div class="ellipsis">
								{{scope.row.Nickname}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="ProductPrice" label="商城售价">
						<template slot-scope="scope">
							<div style="display:flex;justify-content:flex-start;align-items:center;">
								<div style="white-space: nowrap;margin-right: 5px;color:#606266;">￥{{scope.row.ProductPrice}}
									<span v-if="scope.row.ProductMaxPrice>scope.row.ProductPrice">-￥{{scope.row.ProductMaxPrice}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="MinPrice" label="小店售价">
						<template slot-scope="scope">
							<div style="display:flex;justify-content:flex-start;align-items:center;">
								<div style="white-space: nowrap;margin-right: 5px;color:#606266;">￥{{scope.row.MinPrice}}
									<span v-if="scope.row.MaxPrice>scope.row.MinPrice">-￥{{scope.row.MaxPrice}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" label="上下架状态">
						<template slot="header" slot-scope="scope">
							<div class="flex flex-align-center">
								<div>上下架状态</div>
								<el-popover placement="top-start" trigger="hover" width="200"
									content="商品在视频号小店的上下架状态">
									<i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
								</el-popover>
							</div>
						</template>
						<template slot-scope="scope">
							<div>
								<div>
									{{scope.row.Status==5?'上架销售中':(scope.row.Status==0?'--':'已下架')}}
								</div>
								<div class="color-999999" v-if="scope.row.Status==11">自主下架</div>
								<div class="flex flex-align-center color-f56c6c" v-if="scope.row.Status==13">
									<div>违规下架</div>
									<el-popover placement="top-start" trigger="hover" width="200"
										content="违规下架">
										<i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
									</el-popover>
								</div>
								<div class="flex flex-align-center color-f56c6c" v-if="scope.row.Status==14">
									<div>保证金不足下架</div>
									<el-popover placement="top-start" trigger="hover" width="200"
										content="保证金不足下架">
										<i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
									</el-popover>
								</div>
								<div class="flex flex-align-center color-f56c6c" v-if="scope.row.Status==15">
									<div>品牌过期下架</div>
									<el-popover placement="top-start" trigger="hover" width="200"
										content="品牌过期下架">
										<i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
									</el-popover>
								</div>
								<div class="flex flex-align-center color-f56c6c" v-if="scope.row.Status==20">
									<div>商品被封禁</div>
									<el-popover placement="top-start" trigger="hover" width="200"
										content="商品被封禁">
										<i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
									</el-popover>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" label="草稿状态">
						<template slot="header" slot-scope="scope">
							<div class="flex flex-align-center">
								<div>草稿状态</div>
								<el-popover placement="top-start" trigger="hover" width="200"
									content="最近一次提交的商品信息审核状态，审核成功后会自动更新视频号小店商品数据">
									<i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
								</el-popover>
							</div>
						</template>
						<template slot-scope="scope">
							<div>
								<span v-if="scope.row.EditStatus==0">--</span>
								<span v-if="scope.row.EditStatus==2">审核中</span>
								<span v-if="scope.row.EditStatus==4">审核通过</span>
								<div class="flex flex-align-center color-f56c6c" v-if="scope.row.EditStatus==3">
									<div>审核不通过</div>
									<el-popover placement="top-start" trigger="hover" width="200"
										:content="scope.row.CheckFailReason||'审核不通过'">
										<i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
									</el-popover>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AddTime" label="添加时间"></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<div v-if="scope.row.EditStatus==2">
								<buttonPermissions :datas="'videoGoodsListRevocation'" class="margin-right-10">
									<el-button type="text" @click="revocation(scope.row)">撤回审核</el-button>
								</buttonPermissions>
							</div>
							<div class="flex flex-align-center flex-wrap" v-else>
								<buttonPermissions :datas="'videoGoodsListaddProduct'" class="margin-right-10">
									<el-button type="text" @click="eidtGroup(scope.row)" class="pointers">编辑</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'videoGoodsListUpDown'" class="margin-right-10">
									<el-button type="text" v-if="(scope.row.Status!=0&&scope.row.EditStatus==4)||[5,11,13,14,15].includes(scope.row.Status)"
									 @click="toUnmount(scope.row)">{{scope.row.Status==5?'下架':'上架'}}</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'videoGoodsListDel'" class="margin-right-10">
									<el-button type="text" style="color:#f56c6c" @click="singleDelect(scope.row)">删除</el-button>
								</buttonPermissions>
							</div>
						</template>
					</el-table-column>

				</el-table>

				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin:20px 0 0 13px;">

					</div>
					<el-pagination v-if="Total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="Total">
					</el-pagination>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import {
		productList,
		productOpen,
		productDelete,
		productBrandList,
		productGroupList,
		productSpecGet,
		productChangeNameAndNo,
		productChangePrice,
		productSpeChangeStock,
		productBatchOpen,
		productBatchDelete,
		distributorRoleFilterBoxList,
		changeSellReward,
		batchChangeGroupInit,
		batchChangeGroup,
		productBatchAddCart,
		changeVirtualSalesInit,
		changeVirtualSales,
		batchChangeVirtualSales,
		changeSupplierState,
		batchChangeSupplierState,
		sharedMaterial,
		freightTemplateinfo,
		freightTemplateList,

		productsupplierproductagreementinfo,
		productagreesupplierproductagreement,

		channelShopchannelShopProductList,
		channelShopchannelShopProductCancelAudit,
		channelShopchannelShopProductListingOrDelist,
		channelShopchannelShopProductDelete
	} from '@/api/goods'
	import {
		AgentfilterBoxList,
		batchChangeDistributionSave,
		producttimingOpen,
		producttimingBatchOpen,
		productproductAttributeRelationSave
	} from '@/api/TurnTomySelf.js'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import batchCods from "@/components/bathCodes"
	import {
		shortVideoproductIsHaveShortVideo
	} from "@/api/sv3.0.0"
  import notBind from "../components/notBind"
	export default {
		components: {
			buttonPermissions,
			batchCods,
			notBind
		},
		data() {
			return {
				IsBind:false,
				currentSate: 1,
				productNum: 0,
				ProductNames: '',
				errVisible: false,
				maxOptions: { //禁用当前日期之前的日期
					disabledDate(time) {
						//Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
						return time.getTime() < Date.now() - 8.64e7;
					},
				},
				//新版本
				setTomeVisable: false,
				starTime: '',
				recordProduct: {},
				setTimetitle: '',
				Timeindex: null,
				ProductIds: [],
				//结束
				//新增配送方式
				FreightTemplateType: null,
				nofrightData: [],
				shipSendIds: [],
				ShipLoading: false,
				shipSendForm: {
					ChangeType : 0,
					IsOpenExpress: false,
					IsOpenSelfExtract: false,
					IsOpenInCityDelivery:false,
					// IsDistributionSync: false,
					IsDistributionSync: null,
					FreightType: 0,
					FreightTemplateId: null,
				},
				frightData: [],
				TemplateName: '',
				FreightTemplateIdList: [],
				isShowTex: false,
				shipSendVisible: false,
				isMoreShow: false,
				specLoading: false,
				eidiTnames: '编辑名称与编码',
				goodsSorts: null,
				goodsSortsList: [{
						id: 1,
						value: '已发布到供货市场'
					},
					{
						id: 2,
						value: '分销商品'
					}
				],
				virtualsalesTitle: "商品虚拟销量",
				productNameishow: true,
				virtualLoading: false,
				minFactor: null,
				maxFactor: null,
				virtualsalesNum: null,
				virtualsalesProduct: '',
				virtualsalesVisible: false,
				batchaddCarVisible: false,
				carruleForm: {
					IsAddCart: null
				},
				sortNaming: false,
				weiCodeData: {},
				weiCodeShow: false,
				bathSortDatas: [],
				sortsLoading: false,
				sortSearchKey: '',
				eiditeSortVisible: false,
				seletedSortList: [],
				mixListLoading: false,
				contacteditPriceVisable: false,
				contactLoading: false,
				ruleFormMoney: {
					EmployeeSellRewardType: 2,
					EmployeeSellRewardRate: null,
					RecommendSellRewardRate: null,
					MemberSellRewardType: 2,
					ProductSellRewardList: [],
					//级别代理
					AgentProductSellRewardList: []
				},
				moneyDialogShow: false,
				value: '',
				saleStylelist: [{
					id: 0,
					label: '普通商品'
				}, {
					id: 1,
					label: '授权礼包'
				}],
				saleStyle: null,
				SpecTitle: '',
				SpecTitle2: '',
				slectgoodNum: 0,
				isConcts: false,
				productNames: '',
				productSortList: [],
				OrderBy: '',
				IsAsc: '',
				ruleForm: {
					name: '',
					code: '',
					singleprice: '',
					singledesc: '',
					specData: [],
				},
				contactRuleForm: {
					IsSetMixUnitPrice: 0,
					ProductMixList: [],
					contactOnePrice: null
				},
				goodsSelect: [{
						id: 0,
						type: '单品'
					},
					{
						id: 1,
						type: '组合商品'
					},
				],
				listStateList: [{
						id: 1,
						value: '销售中'
					},
					{
						id: 2,
						value: '已售罄'
					},
					{
						id: 3,
						value: '仓库中'
					}
				],
				saleSelect: [{
						id: 0,
						type: '单品销售'
					},
					{
						id: 1,
						type: '组合销售'
					},
				],
				currentItemGoods: '',
				roleList: [],
				listState: '1',
				groupData: [],
				ProductSpecId: '',
				stockData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 3,
				checked: false,
				searchKey: '',
				searchType: 0,
				saleType: '', //销售类型
				goodsType: [], //商品分组
				Status: null, //上下架状态
        StatusList:[
          {
            value:5,
            label:'上架销售中'
          },{
            value:0,
            label:'已下架'
          },
        ],
				priceMin: '',
				priceMax: '',
				saleMin: '',
				saleMax: '',
				multipleSelection: [],
				loading: false,
				editNameVisable: false, //是否显示名称编辑弹窗
				editPriceVisable: false, //是否显示价格编辑弹窗
				stockVisble: false, //是否显示库存弹窗
				productName: "",
				productNumber: "",
				deleteDialogShow: false,
				isCurrentAll: false,
				goodsDelect: {},
				btnLoading: false,
				baseAPI: config.BASE_URL,
				imgUrl: config.IMG_BASE,
				exportUrl: config.EXPORT_URL,
				priceEditdata: {},
				productEditdata: {},
				delecType: null,
				pricestocktype: null,
				currentnum: '',
				addProductShow: true,
				downloadProductShow: true,
				batchProductIds: [],
				goUrls: config.GO_URL,
				VisalProductId: null,
				virtualsalesType: null,
				comparePrice: null,
				changeloading: false,
				ishowIsOpenSupplierProduct: false,
				IsSupplierProduct: false,
				IsRecentUnsalableProduct: null,
				//推广码
				IsprogramPlay: false,
				isAgentModel: false,


				pageloading:false,
				agreementshow:false,
				agreementloading:false,
				agreementrules:{},

				singleChang:{},

				rowmsg:{},
				setStatsShow:false,
				setStatsloading:false,
				setStatstype:1,
				setStatslist:[],
			};
		},
		computed: {
			...mapGetters([
				'routerList',
				'IsMoreShop',
				'mallInfo',
				'ComponentId'
			]),
		},
		beforeMount() {
			// console.log(this.shipSendForm.IsOpenExpress)
			// console.log(this.mallInfo, 'this.mallInfo');
			this.isAgentModel = window.localStorage.getItem('mlmzDistributionModel') == 1
			this.$store.dispatch('GetPcMallName', {}).then(() => {}).catch((e) => {})
			this.goodsType = this.$route.query.ProductGroupId ? [Number(this.$route.query.ProductGroupId)] : []
			this.IsRecentUnsalableProduct = this.$route.query.IsRecentUnsalableProduct ? this.$route.query.IsRecentUnsalableProduct :
				null
			this.ishowIsOpenSupplierProduct = window.localStorage.getItem('IsOpenSupplierProduct') == 'true' ? true : false
			this.getInfor()
			this.getList()
			this.getroleData()
			this.getRouterList()
		},
		methods: {
			toQiDianShop(row){
				this.$router.push({
					path:'/goods/creatGoodDetails',
					query:{
						Id:row.ProductId
					}
				})
			},
			async toUnmount(row){
				try{
					this.loading = true
					let res = await channelShopchannelShopProductListingOrDelist({
						Id:row.Id,
						Type:row.Status==5?1:0
					})
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getList()
					}
				}finally{
					this.loading = false
				}
			},
      revocation(row){
        this.$confirm('是否确认撤回商品审核？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(async () => {
					let res = await channelShopchannelShopProductCancelAudit({
						Id:row.Id
					})
					if(res.IsSuccess){
						this.$message.success('撤回审核成功')
						this.getList()
					}
        })
      },
      
			// 确定删除商品
			singleDelect(row) {
        this.$confirm('删除后不可恢复，是否确认删除？该删除行为，仅删除视频号小店的商品，不影响商城小程序中的数据。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(async () => {
          
          try {
            this.loading = true;
            let result = await channelShopchannelShopProductDelete({
              Id:row.Id
            })
            if (result.IsSuccess) {
              this.$message.success('删除成功')
              this.getList();
            }

          }finally {
            this.loading = false;
          }
        })

			},
			getshopAttribute({list}){
				this.setStatslist = list
				this.confirmSet()
			},
			async confirmSet(){
				try{
					this.setStatsloading = true
					if(this.setStatstype==1){
						let res = await productproductAttributeRelationSave({
							ProductId:this.rowmsg.ProductId,
							ProductAttributeIdList:this.setStatslist
						})
						if(res.IsSuccess){
							this.$message.success('保存成功')
						}
					}else{
						for(let item of this.multipleSelection){
							await productproductAttributeRelationSave({
								ProductId:item.ProductId,
								ProductAttributeIdList:this.setStatslist,
								noError:true
							})
						}
					}
				}finally{
					if(this.setStatstype==2){
						this.$message.success('保存成功')
					}
					this.getList()
					this.setStatsShow = false
					this.setStatsloading = false
				}
			},
			bathattribute(){
				if(!this.multipleSelection||!this.multipleSelection.length){
					this.$message.error('请先选择商品')
					return
				}
				this.setStatstype = 2
				this.rowmsg = {}
				this.setStatsShow = true
			},
			setattribute(row){
				this.rowmsg = row
				this.setStatstype = 1
				this.setStatsShow = true
			},
			async toshortvideo(row){
				// console.log(row)
				let res = await shortVideoproductIsHaveShortVideo({
					ProductId:await row.ProductId
				})
				if(res.IsSuccess){
					// console.log(res)
					this.$router.push({
						path:'/market/shortvideo/detail',
						query:{
							Id:res.Result.Id,
							ProductId:row.ProductId,
							Name:row.Name
						}
					})
				}
			},
			csloeshipSend(){
				this.shipSendForm = {
					ChangeType : 0,
					IsOpenExpress: false,
					IsOpenSelfExtract: false,
					IsOpenInCityDelivery:false,
					// IsDistributionSync: false,
					IsDistributionSync: null,
					FreightType: 0,
					FreightTemplateId: null,
				}
			},
			closeagreement(){
				this.$refs.agreement.scrollTop = 0+'px'
			},
			async knowrules(){
				try{
					this.agreementloading = true
					let res = await productagreesupplierproductagreement()
					// console.log(res)
					if(res.IsSuccess){
						this.$message.success('协议签署成功')
						this.agreementshow = false
						if(this.singleChang.isonce){
							this.tosingleChangMark()
						}else{
							this.tochangeMark()
						}
					}

				}finally{
					this.agreementloading = false
				}
			},
			async getrules(){
				this.pageloading = true
				try{
					let data = {
						AgreementType:3
					}
					let res = await productsupplierproductagreementinfo(data)
					// console.log(res.Result.IsNeedAgree)
					if(res.IsSuccess){
						if(res.Result.IsNeedAgree){
							this.agreementshow = true
							this.agreementrules = res.Result
						}else{
							// console.log(this.singleChang.isonce)
							if(this.singleChang.isonce){
								this.tosingleChangMark()
							}else{
								this.tochangeMark()
							}
						}
					}
				}finally{
					this.pageloading = false
				}
			},
			selectfright(){
				if(this.shipSendForm.ChangeType == 1){
					this.shipSendForm.IsOpenExpress = true
				}
				else{
					if(!this.mallInfo.IsMoreShop){
						this.shipSendForm.IsOpenExpress = true;
					}else{
						this.shipSendForm.IsOpenExpress = false;
					}
				}
			},
			onlytableNumber(obj,index,type){
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				
				if(type == 1){
					this.ruleFormMoney.ProductSellRewardList[index].SellRewardRate = obj
				}
				else if(type == 2){
					this.ruleFormMoney.ProductSellRewardList[index].RecommendSellRewardRate = obj
				}
				else if(type == 3){
					this.ruleFormMoney.ProductSellRewardList[index].SellRewardMoney = obj
				}
				else{
					this.ruleFormMoney.ProductSellRewardList[index].RecommendSellRewardMoney = obj
				}
				
			},
			errmodelClose() {
				this.errVisible = false
				this.getList()
				this.checked = false
			},
			//保存修改时间
			async saveSetime() {
				try {
					this.loading = true
					//单选保存
					if (this.recordProduct.ProductId) {
						let data = {
							ProductId: this.recordProduct.ProductId,
							IsOpen: !this.recordProduct.IsOpen,
							TimingOpenTime: this.starTime,
						}
						let result = await producttimingOpen(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功'
							});
							this.setTomeVisable = false
							this.getList()
						}
					}
					//多选保存
					// index 1 下架时间 2上架时间
					else {

						let data = {
							ProductIdList: this.ProductIds,
							IsOpen: this.Timeindex == 1 ? false : true,
							TimingOpenTime: this.starTime
						}
						let result = await producttimingBatchOpen(data)
						if (result.IsSuccess) {
							if (result.Result) {
								this.currentSate = this.Timeindex
							
								this.setTomeVisable = false
								this.productNum = result.Result.ProductCount
								this.ProductNames = result.Result.ProductNames
								this.errVisible = true
							
							} else {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功'
								});
								this.setTomeVisable = false
								this.getList()
							}

						}
					}

				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//设置上架时间
			setDisplayTime(record, index) {
				// index 1 下架时间 2上架时间
				this.recordProduct = record
				this.Timeindex = index



				this.setTimetitle = index == 1 ? '设置下架时间' : '设置上架时间'
				if (record.ProductId) {
					this.starTime = ''
					this.setTomeVisable = true
				} else {
					if (!this.multipleSelection.length) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择商品'
						});
						return
					}
					this.ProductIds = this.multipleSelection.map(item => {
						return item.ProductId
					})
					this.starTime = ''
					this.setTomeVisable = true
				}

			},
			openNewWindow() {
				window.open('https://www.yuque.com/docs/share/27eae25f-f305-4531-b3e9-9a4cff7a18ab?#')
			},
			//保存配送方式
			saveShipinfo(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.ShipLoading = true

							let data = {
								ChangeType:this.shipSendForm.ChangeType,
								ProductIdList: this.shipSendIds,
								//this.IsMoreShop为true表示为多门店 默认选中快递发货 到点自提则为false
								IsOpenExpress: !this.mallInfo.IsMoreShop?true:this.shipSendForm.IsOpenExpress,
								IsOpenSelfExtract: !this.mallInfo.IsMoreShop?false:this.shipSendForm.IsOpenSelfExtract,
								IsDistributionSync: this.shipSendForm.IsDistributionSync,
								FreightType: this.shipSendForm.FreightType,
								UniformFreight: this.shipSendForm.UniformFreight,
								FreightTemplateId: this.shipSendForm.FreightTemplateId,
								IsOpenInCityDelivery:this.shipSendForm.IsOpenInCityDelivery
							}
							// console.log(data)
							let result = await batchChangeDistributionSave(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.getList()
								this.shipSendVisible = false;
							}
						} catch (e) {
							if (e == '分销商品不支持修改配送方式，已自动为您过滤' || e == '非发布到供货市场商品无需修改供货运费，已自动为您过滤') {
								this.shipSendVisible = false;
								this.getList()
							}
							//TODO handle the exception
						} finally {
							this.ShipLoading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//选择运费模板
			frightChange() {

				if (this.shipSendForm.FreightTemplateId) {
					this.TemplateName = ''
					this.FreightTemplateIdList.map(item => {
						if (item.Id == this.shipSendForm.FreightTemplateId) {
							this.TemplateName = item.TemplateName
						}
					})

				}
				this.getTemplate();
			},
			// 获取运费模板
			async getTemplate() {
				try {
					if (this.shipSendForm.FreightTemplateId) {
						let data1 = {
							Id: this.shipSendForm.FreightTemplateId
						}
						this.frightData = [];
						this.nofrightData = []
						let result = await freightTemplateinfo(data1)
						this.FreightTemplateType = result.Result.FreightTemplateType
						this.frightData = result.Result.DetailList;
						this.nofrightData = result.Result.NonDistributionAreaNames
					}

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			//修改配送方式
			shipChange() {
				// console.log(this.multipleSelection)
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择商品!'
					});
					return
				}
				this.shipSendVisible = true
				this.shipSendForm.ChangeType  = 0
				this.shipSendForm.IsOpenExpress = !this.mallInfo.IsMoreShop
				this.shipSendForm.IsOpenSelfExtract = false
				this.shipSendForm.IsDistributionSync = null
				this.shipSendForm.FreightType = 0
				this.shipSendForm.FreightTemplateId = null
				this.shipSendIds = []
				this.multipleSelection.map(record => {
					this.shipSendIds.push(record.ProductId)
				})
				//配送方式ids
				this.isShowTex = false

				this.$nextTick(() => {
					// this.$refs.shipSendForm.resetFields()
					this.$refs.shipSendForm.clearValidate()
				})
			},
			getRouterList() {
				if (this.routerList.some(item => {
						return item == 'virtualSales' || item == 'batchRemovemark' || item == 'batchPushmark' || item ==
							'MeterialMangebtn' || item == 'shareMeterialbtn' || item == 'deleteProduct' || item == 'sendShipmethod'
					})) {
					this.isMoreShow = true
				} else {
					this.isMoreShow = false
				}
				// console.log(this.routerList)
			},
			//限制输入
			onlyNumber1(obj, index, Type) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}

				if (Type == 1) {
					this.ruleFormMoney.AgentProductSellRewardList[index].SellRewardMoney = obj
				}
				if (Type == 2) {
					this.ruleFormMoney.AgentProductSellRewardList[index].RecommendSellRewardMoney = obj
				}
				if (Type == 3) {
					this.ruleFormMoney.AgentProductSellRewardList[index].IndirectRecommendSellRewardMoney = obj
				}
			},
			//限制输入
			onlyNumber(obj, index) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				//按百分比
				if (index == 1) {
					this.ruleFormMoney.EmployeeSellRewardRate = obj
				}
				//按固定金额
				if (index == 2) {
					this.ruleFormMoney.EmployeeSellRewardMoney = obj
				}

			},
			//素材管理
			MaterialManage(item) {
				this.$router.push({
					path: '/goods/MeterialManagelist',
					query: {
						ProductId: item.ProductId ? item.ProductId : 0,
					}
				});
			},
			speceKey(row) {
				return row.ProductSpecId
			},
			// 虚拟销量初始化
			async getVisal(record) {
				try {
					let result = await changeVirtualSalesInit({
						ProductId: record.ProductId
					})
					this.virtualsalesNum = result.Result.VirtualSales
					this.minFactor = result.Result.VirtualSalesCoefficientMin
					this.maxFactor = result.Result.VirtualSalesCoefficientMax
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.virtualsalesVisible = true
				}

			},
			// 虚拟销量
			setVisal(record) {
				this.productNameishow = true
				this.virtualsalesType = 1
				this.virtualsalesTitle = "商品虚拟销量"
				this.virtualsalesProduct = record.Name
				this.VisalProductId = record.ProductId
				this.getVisal(record)

			},
			// 批量设置虚拟销量
			bathVisale() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择的商品!'
					});
					return
				}
				this.virtualsalesType = 2
				this.virtualsalesTitle = "批量设置商品虚拟销量"
				this.batchProductIds = []
				this.multipleSelection.map(record => {
					this.batchProductIds.push(record.ProductId)
				})
				this.productNameishow = false
				this.virtualsalesVisible = true

			},
			// 设置虚拟销量
			async virtualSave() {
				try {
					if (this.minFactor > this.maxFactor) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '最小系数要小于等于最大系数!'
						});
						return
					}
					if (this.minFactor > 999 || this.maxFactor > 999) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '最大系数999!'
						});
						return
					}

					let result = {}
					// 单个设置虚拟销量
					if (this.virtualsalesType == 1) {
						let data = {
							ProductId: this.VisalProductId,
							VirtualSales: this.virtualsalesNum,
							VirtualSalesCoefficientMin: this.minFactor,
							VirtualSalesCoefficientMax: this.maxFactor
						}
						result = await changeVirtualSales(data)
					} else {

						// 批量设置虚拟销量
						let data1 = {
							ProductIds: this.batchProductIds,
							VirtualSales: this.virtualsalesNum,
							VirtualSalesCoefficientMin: this.minFactor,
							VirtualSalesCoefficientMax: this.maxFactor
						}
						result = await batchChangeVirtualSales(data1)
					}

					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getList()
						this.virtualsalesVisible = false;
					}


				} catch (e) {
					//TODO handle the exception
				} finally {
					this.virtualLoading = false
				}

			},
			// 关闭虚拟销量弹框
			virtualsaleClose() {
				this.virtualsalesProduct = ''
				this.virtualsalesNum = null
				this.minFactor = null
				this.maxFactor = null
			},
			// 加入购物车
			addcarBatchs() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择的商品!'
					});
					return
				}
				this.batchProductIds = []
				this.multipleSelection.map(record => {
					this.batchProductIds.push(record.ProductId)
				})
				this.batchaddCarVisible = true
			},
			// 确定
			sureCarfun(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.sortsLoading = true
							let data = {
								IsAddCart: this.carruleForm.IsAddCart,
								ProductIds: this.batchProductIds
							}
							let result = await productBatchAddCart(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.getList()
								this.batchaddCarVisible = false;
							}
						} catch (e) {
							//TODO handle the exception
						} finally {
							this.sortsLoading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 推广
			weiCodeFun(ProductId) {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pages/detail/detail`,
					Scene: `id=${ProductId}`
				}
			},
			weiCodeCancel(value) {
				this.weiCodeShow = value;
			},
			// 商品分组初始化
			async batchGroupInt() {
				try {
					this.sortNaming = true
					let data = {
						ProductIds: this.batchProductIds,
						KeyWord: this.sortSearchKey
					}
					let result = await batchChangeGroupInit(data)
					this.bathSortDatas = []
					
					result.Result.Results.map(item => {
						let obj = {
							GroupName: item.GroupName,
							ProductGroupId: item.ProductGroupId,
							ProductIds: item.ProductIds,
							SelectState: item.SelectState
						}

						if (item.SelectState == 1) {
							// 所有商品均选中
								obj.isIndeterminate = false,
								obj.checkAll = true

						} else if (item.SelectState == 2) {
							// 部分商品选中
							obj.isIndeterminate = true,
								obj.checkAll = false
						} else if (item.SelectState == 3) {
							// 所有商品都不选中
							obj.isIndeterminate = false,
								obj.checkAll = false
						}
						this.bathSortDatas.push(obj)
					})
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.sortNaming = false
				}

			},

			handleCheckAllChange(val) {
				const itemIndex= this.seletedSortList.findIndex(t=> t.ProductGroupId == val.ProductGroupId)
				
				if(itemIndex>= 0) {
					this.seletedSortList[itemIndex].checkAll = val.checkAll
					this.seletedSortList[itemIndex].isIndeterminate = false
				}else {
					this.seletedSortList.push(val)
				}
				this.bathSortDatas.forEach(t=> {
					if(t.ProductGroupId == val.ProductGroupId) {
						t.isIndeterminate = false
					}
				})
			},
			// 设置分组
			sortsBatchs() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择的商品!'
					});
					return
				}
				this.batchProductIds = []
				this.multipleSelection.map(record => {
					this.batchProductIds.push(record.ProductId)
				})

				this.sortSearchKey = ''

				this.batchGroupInt().then(()=> {
					this.seletedSortList = this.bathSortDatas.filter(t=> {
						return t.SelectState== 1 || t.SelectState== 2
					})
				})
				this.eiditeSortVisible = true
			},
			// 查询分组
			sortsfunFilter() {
				this.batchGroupInt()
			},

			codeChangefun() {
				this.ruleForm.code = this.ruleForm.code.replace(/[\W]/g, '');
			},
			keyFun1(row) {
				return row.ProductSpecId
			},
			// 确认修改分组
			async sureEditSortfun() {
				try {
					this.sortsLoading = true
					let ProductGroupIds = []
					this.seletedSortList.map(item => {
						if (item.checkAll) {
							let obj = {
								ProductGroupId: item.ProductGroupId,
								ProductIds: this.batchProductIds
							}
							ProductGroupIds.push(obj)
						}

						if (item.isIndeterminate) {
							let obj1 = {
								ProductGroupId: item.ProductGroupId,
								ProductIds: item.ProductIds
							}
							ProductGroupIds.push(obj1)
						}
					})
					let data = {
						ProductIds: this.batchProductIds,
						ProductGroups: ProductGroupIds
					}

					let result = await batchChangeGroup(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getList()
						this.eiditeSortVisible = false;
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.sortsLoading = false
				}

			},
			// 价格设置
			pricesetChange() {
				if (this.contactRuleForm.ProductMixList.length > 0 && this.contactRuleForm.IsSetMixUnitPrice == 0) {
					this.contactRuleForm.ProductMixList.map(item => {
						item.UnitPrice = null
					})
					this.contactRuleForm.contactOnePrice = null
					this.$forceUpdate()
				}
			},
			salePriceFun() {
				this.priceFunction()
			},
			// 组合数量的变化
			contactChange() {
				if (this.contactRuleForm.IsSetMixUnitPrice == 1) {
					this.priceFunction()
				}

			},
			savecontacBtn(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.contactLoading = true
						try {
							let ProductPriceList = []
							let obj = {
								ProductSpecId: this.ProductSpecId,
								Price: this.contactRuleForm.contactOnePrice
							}
							ProductPriceList.push(obj)
							let data = {
								ProductId: this.priceEditdata.ProductId,
								ProductPriceList: ProductPriceList,
								IsSetMixUnitPrice: this.contactRuleForm.IsSetMixUnitPrice ? true : false,
								ProductMixList: this.contactRuleForm.ProductMixList
							}

							let result = await productChangePrice(data);
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.contacteditPriceVisable = false;
							}


						} catch (e) {
							console.log(e)
						} finally {
							this.getList()
							this.contactLoading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 计算组合商品价格
			priceFunction() {
				this.contactRuleForm.contactOnePrice = 0
				this.contactRuleForm.ProductMixList.map(item => {
					this.contactRuleForm.contactOnePrice = this.contactRuleForm.contactOnePrice + item.UnitPrice * item.ProductCount
				})
				this.contactRuleForm.contactOnePrice = Math.floor(this.contactRuleForm.contactOnePrice * 100) / 100

				this.$forceUpdate()
			},
			async saveMoneyFun() {
				try {
					let ProductIds = []
					this.multipleSelection.map(item => {
						ProductIds.push(item.ProductId)
					})

					let data = {
						ProductIds: ProductIds,
						EmployeeSellRewardType: this.ruleFormMoney.EmployeeSellRewardType,
						EmployeeSellRewardMoney: this.ruleFormMoney.EmployeeSellRewardMoney,
						EmployeeSellRewardRate: this.ruleFormMoney.EmployeeSellRewardRate,
						MemberSellRewardType: this.ruleFormMoney.MemberSellRewardType,
						ProductSellRewardList: this.ruleFormMoney.ProductSellRewardList,
						AgentProductSellRewardList: this.ruleFormMoney.AgentProductSellRewardList
					}
					let reuslt = await changeSellReward(data)
					if (reuslt.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.moneyDialogShow = false;
					}


				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.getList()
				}

			},

			// 收益弹框关闭
			moneyClosefun() {
				this.ruleFormMoney = {
					EmployeeSellRewardType: 2,
					EmployeeSellRewardRate: null,
					RecommendSellRewardRate: null,
					MemberSellRewardType: 2,
					ProductSellRewardList: [],
					AgentProductSellRewardList: [],
				}
				this.getroleData()
			},
			// 批量设置收益
			moneyBatchs() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择操作的商品!'
					});
					return
				}
				this.moneyDialogShow = true
			},
			// 单个改变商品在供货市场的状态
			singleChangMark(record, type,isonce) {
					this.singleChang = {
						record:record,
						type:type,
						isonce:isonce
					}
				if(type==1){
					
					this.getrules()
				}else{
					// console.log(type)
					this.tosingleChangMark()
				}
				
			},
			async tosingleChangMark(){
				try {
					this.changeloading = true
					// console.log(this.singleChang,'123')
					let data = {
						ProductId: this.singleChang.record.ProductId,
						IsOpenSupplierProduct: this.singleChang.type==1  //等于1表示发布到供货市场
					}
					let result = await changeSupplierState(data)
					// console.log(result,'res')
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.getList()
					this.changeloading = false
				}
			},
			// 批量改变商品在供货市场的状态
			async changeMark(type,isonce) {
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择操作的商品!'
					});
					return
				}
				this.singleChang = {
					type:type,
					record:this.multipleSelection.map(item => {
						return item.ProductId
					}),
					isonce:isonce
				}
				if(type==0){
					this.tochangeMark()
				}else{
					this.getrules()
				}
				

			},
			async tochangeMark(){
				try {
					this.changeloading = true
					// let productIds = []
					// this.multipleSelection.map(item => {
					// 	productIds.push(item.ProductId)
					// })
					let data = {
						ProductIds: this.singleChang.record,
						IsOpenSupplierProduct: this.singleChang.type==1,
					}

					let result = await batchChangeSupplierState(data)

					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.getList()
					this.changeloading = false
				}
			},
			// 获取推手收益列表
			async getroleData() {
				try {
					let result2 = await distributorRoleFilterBoxList()
					result2.Result.map(item => {
						let obj = {
							Id: 0,
							DistributRoleName: item.DistributRoleName,
							MemberDistributorRoleId: item.Id,
							SellRewardMoney: null,
							SellRewardRate: '',
							RecommendSellRewardMoney: null,
							RecommendSellRewardRate: ''
						}
						this.ruleFormMoney.ProductSellRewardList.push(obj)

					})

					// console.log(this.ruleFormMoney.ProductSellRewardList,'推手级别')

					let result3 = await AgentfilterBoxList()
					result3.Result.map(item => {
						let obj1 = {
							Id: 0,
							AgentRoleId: item.AgentRoleId,
							AgentRoleGradeNum: item.AgentRoleGradeNum,
							AgentRoleName: item.AgentRoleName,
							SellRewardMoney: null,
							RecommendSellRewardMoney: null,
							IndirectRecommendSellRewardMoney: null
						}
						this.ruleFormMoney.AgentProductSellRewardList.push(obj1)
					})
					// console.log(this.ruleFormMoney.AgentProductSellRewardList, '代理级别')

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {

				}
			},
			mousereeEnter(row, column, cell, event) {
				this.groupData.map((item, index) => {
					if (item.ProductId == row.ProductId) {
						this.$set(this.groupData[index], 'isShoweite', true)
					}
					return item
				})
				this.groupData.reverse().reverse()
			},
			mouserLeave(row, column, cell, event) {
				this.groupData.map((item, index) => {
					if (item.ProductId == row.ProductId) {
						this.$set(this.groupData[index], 'isShoweite', false)
					}
					return item
				})
				this.groupData.reverse().reverse()
			},

			//筛选数据
			async getInfor() {
				try {

					let result1 = await productGroupList()
					this.productSortList = result1.Result

					// 获取运费模板数据
					let data = {
						OrderBy: 'AddTime',
						IsAsc: false
					}
					let result3 = await freightTemplateList(data)
					this.FreightTemplateIdList = result3.Result

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			keyTypeChange() {
				if (this.searchType == 0) {
					this.saleType = 0;
				} else {
					this.saleType = 1
				}
			},
			saleChanges() {
				if (this.saleType == 0) {
					this.searchType = 0
				} else if (this.saleType == 1) {
					this.searchType = 1
				}

			},
			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
			},

			// 排序
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				this.getList();
			},

			// 获取商品列表
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey, // 搜索关键字
						Status: this.Status, 
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await channelShopchannelShopProductList(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
					this.groupData.map(item => {
						item.isShoweite = false
					})


				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false
					}, 500)

				}

			},
			//顶部tab切换事件
			filterFun(tab, event) {
				this.checked = false;
				this.goodsFilter()
			},
			// 搜索
			goodsFilter() {
				this.currentPage = 1;
				this.getList();
			},
			handleSelectionChange(val) {

				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.groupData.length ? true : false
				if (this.isCurrentAll) {
					this.checked = true
				} else {
					this.checked = false
				}
			},
			tabCheckedChange() {
				this.$refs['multipleTable'].toggleAllSelection();
			},
			// 导出
			async exportFun() {
				this.loading = true;

				try {
					let url = this.exportUrl + '/pc/product/export?' +
						'&Keywords=' + this.searchKey +
						'&Status=' + (this.Status==null?'':this.Status) +
						
						

					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}


			},
			//批量删除
			deleteBatchs() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择操作的商品!'
					});
					return
				}
				this.delecType = 2;
				this.slectgoodNum = this.multipleSelection.length;
				this.currentItemGoods = ''

				this.deleteDialogShow = true;
			},

			async doublesDelect() {
				try {

					let ProductIdList = [];
					this.multipleSelection.map(item => {
						ProductIdList.push(item.ProductId)
					})

					let result = await productBatchDelete({
						ProductIdList: ProductIdList
					});
					console.log(result, '批量删除')
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.deleteDialogShow = false;
					} else {
						this.currentItemGoods = result.Result
						this.currentnum = result.Message
					}

				} catch (e) {
					this.currentItemGoods = e
					console.log(e)
				} finally {
					this.getList();
				}

			},


			//批量上架
			async addBatchs(type) {
				try {
					if (this.multipleSelection.length < 1) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择操作的商品!'
						});
						return
					}
					let ProductIdList = [];
					this.multipleSelection.map(item => {
						ProductIdList.push(item.ProductId)
					})
					let data = {
						ProductIdList: ProductIdList,
						IsOpen: (type == 1) ? false : true
					}
					let result = await productBatchOpen(data);
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.goodsFilter()
				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			productEdit(item) {
				this.IsSupplierProduct = item.IsSupplierProduct
				if (item.IsSupplierProduct) {
					this.eidiTnames = '编辑名称'
				} else {
					this.eidiTnames = '编辑名称与编码'
				}
				this.productEditdata = item;
				this.ruleForm.name = item.Name;
				this.ruleForm.code = item.ProductNo
				this.editNameVisable = true
			},
			// 编辑商品价格
			priceEdit(item) {
				this.SpecTitle = item.SpecTitle
				this.SpecTitle2 = item.SpecTitle2
				this.priceEditdata = item;
				this.pricestocktype = 1
				this.getDataEdit();
				if (item.ProductType == 0) {
					this.editPriceVisable = true
				} else {
					this.contacteditPriceVisable = true
				}

			},
			stockEdit(item) {
				this.SpecTitle = item.SpecTitle
				this.SpecTitle2 = item.SpecTitle2
				this.priceEditdata = item;
				this.ruleForm.specData = [];
				this.getDataEdit();
				this.pricestocktype = 2
				this.stockVisble = true
			},

			// 多规格分销商品价格变化
			tablepriceChange(record) {
				this.comparePrice = record.RetailPriceMin
				this.competeProft()
			},
			// 单规格价格变化
			sintablepriceChange() {
				this.singleCompeteProft()
			},

			//多规格计算利润
			competeProft() {
				this.ruleForm.specData.map(item => {
					item.suppierProfit = (item.Price - item.SupplierMallPrice).toFixed(2)
					// item.suppierProfit = (item.Price - item.RetailPriceMin).toFixed(2)
					return item
				})
			},
			// 单规格计算利润
			singleCompeteProft() {
				// let minPrice=this.ruleForm.RetailPrice.split("~")[0]
				// this.ruleForm.suppierProfit = (Number(this.ruleForm.singleprice) - Number(minPrice)).toFixed(2)

				this.ruleForm.suppierProfit = (Number(this.ruleForm.singleprice) - Number(this.ruleForm.SupplierMallPrice)).toFixed(
					2)
			},
			async getDataEdit() {
				try {
					this.specLoading = true
					this.productNames = this.priceEditdata.Name;
					let result = await productSpecGet({
						ProductId: this.priceEditdata.ProductId
					})
					let ProductSpecList = result.Result.ProductSpecList
					if (ProductSpecList.length == 1 && (!ProductSpecList[0].SpecValue && (!ProductSpecList[0].SpecValue2))) {
						// this.ruleForm.specData=[];
						this.ProductSpecId = ProductSpecList[0].ProductSpecId
						this.ruleForm = {
							name: this.ruleForm.singleprice,
							code: this.ruleForm.singleprice,
							singleprice: ProductSpecList[0].Price,
							singledesc: ProductSpecList[0].Stock,
							SupplierMallPrice: ProductSpecList[0].SupplierMallPrice,
							RetailPriceMin: ProductSpecList[0].RetailPriceMin,
							RetailPriceMax: ProductSpecList[0].RetailPriceMax,
							RetailPrice: ProductSpecList[0].RetailPriceMin + '~' + ProductSpecList[0].RetailPriceMax,
							suppierProfit: Number(ProductSpecList[0].Price) - Number(ProductSpecList[0].SupplierMallPrice),
							// suppierProfit: Number(ProductSpecList[0].Price) - Number(ProductSpecList[0].RetailPriceMin),
							specData: []
						}
						this.singleCompeteProft()
					} else {
						this.ruleForm.specData = result.Result.ProductSpecList;
						this.competeProft()
					}

					if (this.priceEditdata.ProductType == 1) {
						this.mixListLoading = true
						this.contactRuleForm.IsSetMixUnitPrice = result.Result.IsSetMixUnitPrice ? 1 : 0;
						this.contactRuleForm.ProductMixList = []
						result.Result.ProductMixList.map(item => {
							let obj = {
								ProductMixId: item.ProductMixId,
								Name: item.Name,
								Spec: item.Spec,
								Price: item.Price,
								Stock: item.Stock,
								ProductCount: item.ProductCount ? item.ProductCount : null,
								UnitPrice: item.UnitPrice
							}
							this.contactRuleForm.ProductMixList.push(obj)
						})
						this.contactRuleForm.contactOnePrice = result.Result.ProductSpecList[0].Price
						if (result.Result.IsSetMixUnitPrice) {
							this.priceFunction()
						}

					}

				} catch (e) {
					console.log(e)
				} finally {
					this.specLoading = false
					this.mixListLoading = false
				}

			},
			// 保存编辑库存
			async savestockBtn() {
				try {
					this.btnLoading = true;
					let newSpeceData = [];
					let arryDatas = []
					let stockArry = []
					let isNegative = []
					let outPricedata = []
					let outStockdata = []
					if (this.ruleForm.specData.length) {
						this.ruleForm.specData.map(item => {
							if (Number(item.Price) < 0) {
								isNegative.push(item)
							}
							let obj = {
								ProductSpecId: item.ProductSpecId,
								Price: item.Price,
								Stock: item.Stock
							}
							newSpeceData.push(obj)
							if (!item.Price && item.Price !== 0) {
								arryDatas.push(item)
							}
							if (item.Price > 100000000) {
								outPricedata.push(item)
							}
							if (!item.Stock && item.Stock !== 0) {
								stockArry.push(item)
							}
							if (item.Stock > 100000000) {
								outStockdata.push(item)
							}
						})
						if (stockArry.length && this.pricestocktype == 2) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请完善库存信息!'
							});
							this.btnLoading = false
							return
						}
						if (outStockdata.length && this.pricestocktype == 2) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '商品库存不能超过100000000!'
							});
							this.btnLoading = false
							return
						}

					} else {
						if (!this.ruleForm.singledesc && this.pricestocktype == 2) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请设置正确的库存!'
							});
							this.btnLoading = false
							return
						}
						if (this.ruleForm.singledesc && this.pricestocktype == 2 && this.ruleForm.singledesc > 100000000) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '商品库存不能超过100000000!'
							});
							this.btnLoading = false
							return
						}
						let objs = {
							ProductSpecId: this.ProductSpecId,
							Price: this.ruleForm.singleprice,
							Stock: this.ruleForm.singledesc
						}
						newSpeceData.push(objs)
					}

					let data1 = {
						ProductId: this.priceEditdata.ProductId,
						ProductStockList: newSpeceData
					}
					let result = await productSpeChangeStock(data1);

					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.stockVisble = false;
					}


				} catch (e) {
					console.log(e)
				} finally {
					this.goodsFilter()
					this.btnLoading = false;
				}
			},


			// 保存编辑价格	
			savePriceBtn(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.btnLoading = true;
							let newSpeceData = [];
							if (this.ruleForm.specData.length) {
								this.ruleForm.specData.map(item => {
									let obj = {
										ProductSpecId: item.ProductSpecId,
										Price: item.Price,
										Stock: item.Stock
									}
									newSpeceData.push(obj)
								})

							} else {
								let objs = {
									ProductSpecId: this.ProductSpecId,
									Price: this.ruleForm.singleprice,
									Stock: this.ruleForm.singledesc
								}
								newSpeceData.push(objs)
							}

							let data = {
								ProductId: this.priceEditdata.ProductId,
								ProductPriceList: newSpeceData
							}

							let data1 = {
								ProductId: this.priceEditdata.ProductId,
								ProductStockList: newSpeceData
							}
							let result = await productChangePrice(data);

							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.editPriceVisable = false;
							}

						} catch (e) {
							console.log(e)
						} finally {
							this.goodsFilter()
							this.btnLoading = false;
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},
			eidtGroup(item,isagency,isjigsaw) {
				// console.log(isagency)
				this.isShowEdit = !this.isShowEdit
				let productId = item.Id ? item.Id : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/videoShop/videoGoodsDetails?Id=' + productId
				// url += '&isagency=' + isagency
				// url += '&isjigsaw=' + isjigsaw
				// window.open(url)
				this.$router.push({
					path:'/videoShop/videoGoodsDetails',
					query:{
						Id:productId
					}
				})

			},
			deleteChose(record) {
				this.delecType = 1;
				this.goodsDelect = record;

				this.slectgoodNum = 1;
				this.deleteDialogShow = true
			},
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								ProductId: this.productEditdata.ProductId,
								Name: this.ruleForm.name,
								ProductNo: this.ruleForm.code
							}

							let result = await productChangeNameAndNo(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.editNameVisable = false;
							}

						} catch (e) {
							console.log(e)
						} finally {
							this.goodsFilter()
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},

			// 上下架
			async displyFun(record, type) {

				this.loading = true;
				try {
					let data = {
						ProductId: record.ProductId,
						IsOpen: type == 1 ? true : false
					}
					let result = await productOpen(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
					this.currentPage = 1;
					this.getList();
				}

			},


			shareMaterial(item) {
				this.setSharedMaterial(item);
			},

			//是否分享素材
			async setSharedMaterial(item) {
				try {
					this.loading = true;
					let result = await sharedMaterial({
						ProductId: item.ProductId,
						IsSharedMaterial: !item.IsSharedMaterial
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: item.IsSharedMaterial ? '取消共享成功!' : '开启共享成功!'
						});
					}

				} catch (e) {

				} finally {
					this.loading = false;
					this.getList()
				}
			}

		}
	};
</script>

<style lang = "less" scoped>
.sel-noborder{

	::v-deep input{
		border:none
	}
}
.tableFont{
	background:#F5F5F5;
	color:#909399;
	border: 1px solid #EBEEF5;
	height: 44px;
	line-height: 44px;
	padding-left: 10px;
	font-weight: bold;
}

	.yellowbox{
		width: 80%;
		height: 36px;
		background: #FDF6EC;
		border-radius: 2px 2px 2px 2px;
		margin:0px auto;
		opacity: 1;
		border: 1px solid #E6A23C;
		font-size: 14px;
		font-weight: 400;
		color: #E6A23C;
		line-height: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.yellowbox{
		width: 80%;
		height: 36px;
		background: #FDF6EC;
		border-radius: 2px 2px 2px 2px;
		margin:0px auto;
		opacity: 1;
		border: 1px solid #E6A23C;
		font-size: 14px;
		font-weight: 400;
		color: #E6A23C;
		line-height: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
</style>

<style lang="less">
	.virtualsalesBox {
		::v-deep .el-dialog__body {
			padding: 0 20px 10px !important
		}
	}

	.nameboxClass {
		.el-dialog__body {
			padding: 0;
		}

	}

	.ml-goodList {
		.good-tab {
			position: relative;

			.tab-btn {
				position: absolute;
				right: 10px;
				top: 0px;
				z-index: 99;
			}
		}

		.product-info>img {
			width: 80px;
			height: 80px;
		}

		.setPricebos {
			.el-form-item {
				margin-bottom: 0;
			}
		}

		.el-dialog__body {
			padding: 10px 20px !important
		}

		.bottom_delete {
			border: 1px solid #f5f5f5;
			margin-left: 10px;
			padding: 5px 13px;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.pointers {
			cursor: pointer;
		}

		.sorts-names {
			max-height: 400px;
			overflow: auto;
			margin-top: 20px;
		}

		.sorttable-top {
			width: 100%;
			background: #f8f8f9;
			padding: 15px;
		}

		.sortTable-body {
			width: 100%;
			padding: 15px;
		}
	}

	.tool-style {
		// width: 80px;
	}

	.tablered {
		color: red;
		vertical-align: -3px;
		margin-right: 5px;
	}
	.dialogtitle{
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 18px;
		/* font-weight: bold; */
		color: #303133;
	}
	.agrementcontent{
		max-height:500px;
		overflow:hidden;
		overflow-y:auto;
		/* border:1px solid black; */
		width: 95%;
		margin:0px auto;
		// white-space: pre;
		color: #606266;
		font-size: 14px;
	}
	.flexCol-center{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.nativeInput:focus{
		border-color: #409EFF;
		// outline: none;
		outline-color: #409EFF;;
		outline-width: 1px;
	}
	
	.inputTextAlignLeft input{
		text-align: left!important;
	}
</style>
